import React, { useState, useEffect } from "react"
import { useQuery } from "@apollo/client"
import { getActiveUserQuery } from "queries/queries"

// MUI
import Typography from "@mui/material/Typography"
import Alert from "@mui/material/Alert"
import Collapse from "@mui/material/Collapse"
import IconButton from "@mui/material/IconButton"
import CloseIcon from "@mui/icons-material/Close"

import { ResendEmailVerification } from "components/ResendEmailVerification"

export const EmailAlertBar = () => {
  const { loading, data } = useQuery(getActiveUserQuery)
  const [isOpen, setIsOpen] = useState(false)

  useEffect(() => {
    // console.log(data.activeUser)
    if (loading) {
      return
    } else if (data?.activeUser?.emailVerified === false || data?.activeUser?.emailVerified === null) {
      setIsOpen(true)
    } else {
      setIsOpen(false)
    }
  }, [data?.activeUser?.emailVerified, loading])

  return (
    <>
      <Collapse in={isOpen} sx={{ px: { xs: 2, md: 6 } }}>
        <Alert
          sx={{ mt: 1 }}
          variant="outlined"
          severity="warning"
          action={
            <IconButton
              aria-label="close"
              color="inherit"
              size="small"
              onClick={() => {
                setIsOpen(false)
              }}
            >
              <CloseIcon fontSize="inherit" />
            </IconButton>
          }
        >
          <Typography>
            Email unverified, please check your email to verify your account.
            <ResendEmailVerification />
          </Typography>
        </Alert>
      </Collapse>
    </>
  )
}
