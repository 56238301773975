import React from "react"
import { useState, useContext, useEffect, useRef } from "react"
import { useQuery, useApolloClient } from "@apollo/client"
import Fuse from "fuse.js"
import { useTheme } from "@mui/material/styles"

// MUI
import Grid from "@mui/material/Grid"
import Box from "@mui/material/Box"
import Card from "@mui/material/Card"
import Button from "@mui/material/Button"
import Typography from "@mui/material/Typography"
import SearchIcon from "@mui/icons-material/Search"
import ClearIcon from "@mui/icons-material/Clear"
import KeyboardCommandKeyIcon from "@mui/icons-material/KeyboardCommandKey"

// Components
import { InputTextField } from "components/InputTextField"
import { LinkSearchList } from "./LinkSearchList"
import { LinkSearchDetails } from "./LinkSearchDetails"
import { LoadingBar } from "./LoadingBar"

// Contexts
import { SelectedCategoriesContext } from "contexts/SelectedCategoriesContext"
import { SelectedTagsContext } from "contexts/SelectedTagsContext"
import { SelectedOrganizationsContext } from "contexts/SelectedOrganizationsContext"
import { PersonalLinksSelectedContext } from "contexts/PersonalLinksSelectedContext"
import { FavoritesSelectedContext } from "contexts/FavoritesSelectedContext"

// Utils
import { getLinksQuery, getPaginatedLinksQuery } from "queries/queries"
import { createFuseFuzzySearch } from "Utils/Utils"

// Types
import { LinkInterface } from "interfaces/Link"
import { IsStrictSearchContext } from "contexts/IsStrictSearchContext"

export function LinkSearch() {
  const searchRef = useRef<HTMLElement>(null)
  const [linkData, setLinkData] = useState<LinkInterface[]>([])
  const [offset, setOffset] = useState(0)
  const [filteredResults, setFilteredResults] = useState<Fuse.FuseResult<LinkInterface>[]>([])
  const { selectedCategoriesState } = useContext(SelectedCategoriesContext)
  const { selectedTagsState } = useContext(SelectedTagsContext)
  const { selectedOrganizationsState } = useContext(SelectedOrganizationsContext)
  const { personalLinksSelectedState } = useContext(PersonalLinksSelectedContext)
  const { favoritesSelectedState } = useContext(FavoritesSelectedContext)
  const { isStrictSearch } = useContext(IsStrictSearchContext)
  const [searchText, setSearchText] = useState("")
  const client = useApolloClient()
  const theme = useTheme()

  const { data, fetchMore } = useQuery(getPaginatedLinksQuery, {
    variables: {
      offset: 0,
      limit: 100,
    },
  })

  const readLinksResponse = client.readQuery({
    query: getLinksQuery,
  })

  const getHasNextPage = (data: any) => {
    if (!data?.links) {
      return false
    } else if (data.links.length >= data.totalLinks) {
      return false
    }
    return true
  }

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setSearchText(event.target.value)
  }

  const linksFromSelectedCategoriesAndTags = () => {
    // given the array of all of this users links (data) this function filters out all links that don't satisfy
    // the current search query and active filters. Links must have at least one of the active filters.
    const linkDataToParse = data ? data.links : []

    let categoryFiltered = []
    let tagFiltered = []
    let organizationFiltered = []
    let personalFiltered = []
    let favoritesFiltered = []

    // add selected categories
    if (Object.values(selectedCategoriesState).includes(true)) {
      // if any category has been selected
      categoryFiltered = linkDataToParse.filter((link: LinkInterface) => {
        return link.category?.id && selectedCategoriesState[link.category.id]
      })
    }

    // add selected tags, unless their category has been selected (and thus added above)
    if (Object.values(selectedTagsState).includes(true)) {
      // if any tag has been selected
      tagFiltered = linkDataToParse.filter((link: LinkInterface) => {
        if (link.category?.id && selectedCategoriesState[link.category.id]) {
          // return nothing if given link has already been added to results because
          // it's category has been selected
          return false
        }

        if (!isStrictSearch) {
          // link can contain any of the selected tags
          return (
            link?.tags?.findIndex((tag) => {
              return selectedTagsState[tag.id]
            }) !== -1
          )
        } else {
          // link must contain ALL of the selected tags
          let tagIds = Object.keys(selectedTagsState)
          tagIds = tagIds.filter((tagId: string) => {
            // @ts-ignore
            return selectedTagsState[tagId] === true
          })

          const linkTagIds = link?.tags?.map((tag) => {
            return tag.id.toString()
          })

          if (tagIds?.length && tagIds.every((tagId) => linkTagIds?.includes(tagId))) {
            return true
          }
          return false
        }
      })
    }

    // add selected organizations, unless their category or tag has been selected (and thus added above)
    if (Object.values(selectedOrganizationsState).includes(true)) {
      // if any category has been selected
      organizationFiltered = linkDataToParse.filter((link: LinkInterface) => {
        if (link.category?.id && selectedCategoriesState[link.category.id]) {
          // return nothing if given link has already been added to results because
          // it's category has been selected
          return false
        }
        if (
          link?.tags?.findIndex((tag) => {
            return selectedTagsState[tag.id]
          }) !== -1
        ) {
          return false
        }
        return link.organization && selectedOrganizationsState[link.organization.id]
      })
    }

    // add personal links if personalLinksSelectedState is true, unless their category or tag has been selected (and thus added above)
    if (personalLinksSelectedState === true) {
      personalFiltered = linkDataToParse.filter((link: LinkInterface) => {
        if (link.category?.id && selectedCategoriesState[link.category.id]) {
          // return nothing if given link has already been added to results because
          // it's category has been selected
          return false
        }
        if (
          link?.tags?.findIndex((tag) => {
            return selectedTagsState[tag.id]
          }) !== -1
        ) {
          return false
        }
        return link.organization === null
      })
    }
    // add favorites if  favoritesSelectedState is true, unless their category, tag, or organization has been selected, or the link is already part of personalLinksSelectedState if it is selected (and thus added above)
    if (favoritesSelectedState === true) {
      favoritesFiltered = linkDataToParse.filter((link: LinkInterface) => {
        // don't add if link has already been added because of its category
        if (link.category?.id && selectedCategoriesState[link.category.id]) {
          // return nothing if given link has already been added to results because
          // it's category has been selected
          return false
        }
        // don't add if link has already been added to results because of its tags
        if (
          link?.tags?.findIndex((tag) => {
            return selectedTagsState[tag.id]
          }) !== -1
        ) {
          return false
        }

        // don't add link if link has already been added because of its organization
        if (link.organization && selectedOrganizationsState[link.organization.id]) {
          return false
        }

        // don't add link if link has already been added because of personalLinksSelectedState
        if (!link.organization && personalLinksSelectedState) {
          return false
        }

        if (!link.userLinkFavorites) {
          return false
        }
        return !!(link.userLinkFavorites[0] && link.userLinkFavorites[0].id)
      })
    }

    if (
      Object.values(selectedCategoriesState).includes(true) ||
      Object.values(selectedTagsState).includes(true) ||
      Object.values(selectedOrganizationsState).includes(true) ||
      personalLinksSelectedState === true ||
      favoritesSelectedState === true
    ) {
      let answer = categoryFiltered
        .concat(tagFiltered)
        .concat(organizationFiltered)
        .concat(personalFiltered)
        .concat(favoritesFiltered)
      return answer
    } else {
      return linkDataToParse
    }
  }

  const strictSearchLinksFromSelectedCategoriesAndTags = () => {
    // given the array of all of this users links (data) this function filters out all links that don't satisfy
    // the current search query and active filters. Links must satisfy all filters
    const linkDataToParse = data ? data.links : []

    const filteredLinks = linkDataToParse.filter((link: LinkInterface) => {
      // return false if any filter is not satisfied, else return true
      const isCategoryFilterActive = Object.values(selectedCategoriesState).includes(true)
      const isTagFilterActive = Object.values(selectedTagsState).includes(true)
      const isOrganizationFilterActive = Object.values(selectedOrganizationsState).includes(true)
      const isPersonalLinksSelected = personalLinksSelectedState
      const isFavoritesSelected = favoritesSelectedState

      if (isCategoryFilterActive) {
        if (!link.category || !selectedCategoriesState[link?.category?.id]) {
          return false
        }
      }

      if (isTagFilterActive) {
        let tagIds = Object.keys(selectedTagsState)
        tagIds = tagIds.filter((tagId: string) => {
          // @ts-ignore
          return selectedTagsState[tagId] === true
        })
        const linkTagIds = link?.tags?.map((tag) => {
          return tag.id.toString()
        })

        if (tagIds?.length && tagIds.every((tagId) => linkTagIds?.includes(tagId))) {
          // passed
        } else {
          return false
        }
      }

      if (isOrganizationFilterActive) {
        if (!link.organization && !isPersonalLinksSelected) {
          // link does not have an org, user has not filtered by personal links
          return false
        } else if (!link.organization && isPersonalLinksSelected) {
          // link does not have an org, user has filtered by personal links
        } else if (link.organization && !selectedOrganizationsState[link.organization.id]) {
          return false
        }
      }

      if (isPersonalLinksSelected) {
        if (link.organization && isOrganizationFilterActive) {
          // do nothing
        } else if (link.organization && !isOrganizationFilterActive) {
          return false
        }
      }

      if (isFavoritesSelected) {
        if (!link.userLinkFavorites?.length) {
          return false
        }
      }

      return true
    })

    return filteredLinks
  }

  useEffect(() => {
    const listener = (event: any) => {
      if ((event.ctrlKey && event.key === "k") || (event.metaKey && event.key === "k")) {
        event.preventDefault()
        searchRef.current?.focus()
      }
    }
    document.addEventListener("keydown", listener)
    return () => {
      document.removeEventListener("keydown", listener)
    }
  }, [])

  useEffect(() => {
    // update offset when we receive more links from backend
    if (readLinksResponse && readLinksResponse.links) {
      setOffset(readLinksResponse.links.length)
    }
  }, [readLinksResponse])

  useEffect(() => {
    // fetchMore links when and only when offset changes
    if (data && fetchMore) {
      const hasNextPage = getHasNextPage(data)

      if (hasNextPage && offset <= data?.links?.length) {
        fetchMore({ variables: { offset: offset, limit: 100 } })
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [offset])

  useEffect(() => {
    // todo make this use selectedOrganizationsState too
    // todo refactor to only iterate once instead of once for cats, once for tags, once for orgs
    if (isStrictSearch) {
      setLinkData(strictSearchLinksFromSelectedCategoriesAndTags())
    } else {
      setLinkData(linksFromSelectedCategoriesAndTags())
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    data,
    selectedCategoriesState,
    selectedTagsState,
    selectedOrganizationsState,
    personalLinksSelectedState,
    favoritesSelectedState,
    isStrictSearch,
  ])

  useEffect(() => {
    const options = {
      keys: ["title", "category.title", "tags.title", "organization.name", "url"],
      threshold: 0.4,
    }

    // Set filteredResults after the specified delay
    const handler = setTimeout(() => {
      const newFilteredResults = createFuseFuzzySearch<LinkInterface>(linkData, options, searchText)
      setFilteredResults(newFilteredResults)
    }, 250)

    // Return a cleanup function that will be called every time useEffect is re-called. useEffect will only be re-called
    // if data or searchText changes (see the inputs array below). This is how we prevent debouncedValue from changing if
    // searchText is changed within the delay period. Timeout gets cleared and restarted.
    return () => {
      clearTimeout(handler)
    }
  }, [linkData, searchText])

  const keyboardKey = {
    border: 1,
    borderColor: theme.palette.divider,
    borderRadius: "5px",
    py: 0.25,
    px: 1,
    mx: 0.5,
    color: "text.secondary",
  }

  const macAdornment = () => {
    return (
      <Box sx={{ display: { xs: "none", md: "flex" } }}>
        <Box sx={keyboardKey}>
          <KeyboardCommandKeyIcon
            sx={{ fontSize: "20px", display: "flex", alignItems: "center", justifyContent: "center" }}
          />
        </Box>
        <Box sx={keyboardKey}>
          <Typography variant="caption">K</Typography>
        </Box>
      </Box>
    )
  }

  const windowsAdornment = () => {
    return (
      <Box sx={{ display: { xs: "none", md: "flex" } }}>
        <Box sx={keyboardKey}>
          <Typography variant="caption">ctrl</Typography>
        </Box>
        <Box sx={keyboardKey}>
          <Typography variant="caption">K</Typography>
        </Box>
      </Box>
    )
  }

  const MacAndWindowsAdornment = () => {
    return (
      <Box
        sx={{
          display: { xs: "none", md: "flex" },
          alignItems: "center",
          flexWrap: "nowrap",
          minWidth: "170px",
        }}
      >
        {macAdornment()}
        <Typography sx={{ px: 1, color: "text.secondary" }}>/</Typography>
        {windowsAdornment()}
      </Box>
    )
  }

  const clearSearchAdornment = () => {
    return (
      <Box sx={{ display: { xs: "none", md: "flex" } }}>
        <Button
          color="error"
          variant="outlined"
          onClick={() => {
            setSearchText("")
          }}
        >
          <ClearIcon sx={{ fontSize: "20px", display: "flex", alignItems: "center", justifyContent: "center" }} />
        </Button>
      </Box>
    )
  }

  const endAdornment = () => {
    if (searchText.length > 0) {
      return clearSearchAdornment()
    }
    if (window.navigator.userAgent.includes("Macintosh")) {
      return macAdornment()
    } else if (window.navigator.userAgent.includes("Windows")) {
      return windowsAdornment()
    } else {
      return MacAndWindowsAdornment()
    }
  }

  const startAdornment = () => {
    return <SearchIcon sx={{ mr: 1 }} />
  }

  return (
    <>
      <Box sx={{ display: "flex", mt: 1 }}>
        <Box sx={{ mx: 1, flexGrow: 1 }}>
          <InputTextField
            reference={searchRef}
            autoFocus={true}
            autoComplete={"off"}
            name="SearchLinks"
            helperText="Search Links"
            handleChange={handleChange}
            canToggleTextFieldVisibility={false}
            shouldShowTextField={true}
            textField={searchText}
            handleClickShowTextField={() => {}}
            error={false}
            errorText={""}
            startAdornment={startAdornment()}
            endAdornment={endAdornment()}
          />
        </Box>
        <Box sx={{ mr: 1, display: "flex" }}>
          <LinkSearchDetails
            searchText={searchText}
            setSearchText={setSearchText}
            searchFieldRef={searchRef}
            filteredResults={filteredResults}
          />
        </Box>
      </Box>
      <Grid container spacing={0} sx={{ pt: 1 }}>
        <Grid item xs={12}>
          <LoadingBar loadedLinkCount={data?.links?.length} totalLinkCount={data?.totalLinks} />
          <Card sx={{}}>
            <LinkSearchList filteredResults={filteredResults} />
          </Card>
        </Grid>
      </Grid>
    </>
  )
}
