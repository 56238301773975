import React, { useContext, useState, useEffect, useRef } from "react"

import Snackbar from "@mui/material/Snackbar"
import Alert from "@mui/material/Alert"
import Link from "@mui/material/Link"
import { SnackBarContext } from "contexts/SnackBarContext"
import { SnackBarStateInterface } from "contexts/SnackBarContext"

const usePrevious = <T extends any>(value: T): T | undefined => {
  const ref = useRef<T>()
  useEffect(() => {
    ref.current = value
  })
  return ref.current
}

export const SnackBar = () => {
  const { snackBarState, setSnackBarState } = useContext(SnackBarContext)
  const [isConsecutive, setIsConsecutive] = useState(false)
  // const previousSnackBarState = useRef<SnackBarStateInterface>()
  const previousSnackBarState = usePrevious(snackBarState)

  const handleClose = (event: React.SyntheticEvent | Event, reason?: string) => {
    if (reason === "clickaway") {
      return
    }

    // dont clear message, else the message disappears instantly but the fade out
    // animation takes time and the snackBar going away looks janky.
    setSnackBarState({
      isOpen: false,
      snackBarType: snackBarState.snackBarType,
      message: snackBarState.message,
    })
  }

  useEffect(() => {
    // if the snackbar is already open, set isConsecutive to True and isOpen to false
    // this is pat 1 of
    if (snackBarState.isOpen && previousSnackBarState && previousSnackBarState.isOpen) {
      setIsConsecutive(true)
      setSnackBarState({
        isOpen: false,
        snackBarType: snackBarState.snackBarType,
        message: snackBarState.message,
      })
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [previousSnackBarState])

  useEffect(() => {
    // if isConsecutive, set isConsecutive to false and isOpen to true
    if (isConsecutive) {
      setIsConsecutive(false)

      setSnackBarState({
        isOpen: true,
        snackBarType: snackBarState.snackBarType,
        message: snackBarState.message,
      })
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isConsecutive])

  const snackbarLink = () => {
    if (snackBarState.url) {
      return (
        <Link target="_blank" href={snackBarState.url}>
          {snackBarState.urlText}
        </Link>
      )
    }
  }

  return (
    <>
      <Snackbar open={snackBarState.isOpen} autoHideDuration={4000} onClose={handleClose}>
        <Alert
          variant="filled"
          onClose={handleClose}
          severity={snackBarState.snackBarType}
          sx={{ width: "100%" }}
          aria-live="assertive"
          role="alert"
        >
          {snackBarState.message} {snackbarLink()}
        </Alert>
      </Snackbar>
    </>
  )
}
