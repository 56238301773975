import React from "react"

// Components
import { AccountDetails } from "components/AccountDetails"
import { BackToLinksSubHeader } from "components/BackToLinksSubHeader"

export function AccountPage() {
  return (
    <>
      <BackToLinksSubHeader />
      <AccountDetails />
    </>
  )
}
