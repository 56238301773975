import React from "react"

// Components
import { CreateOrganization } from "../components/CreateOrganization"
import { ListUsersOrganizations } from "../components/ListUsersOrganizations"
import { BackToLinksSubHeader } from "../components/BackToLinksSubHeader"

export const OrganizationsPage = () => {
  return (
    <>
      <BackToLinksSubHeader />
      <CreateOrganization />
      <ListUsersOrganizations />
    </>
  )
}
