import { useNavigate } from "react-router-dom"
import Button from "@mui/material/Button"

export const BackToLinksSubHeader = () => {
  // a 'back to links button' to put on top of logged in pages where it should be easy to get back to a user's links
  const navigate = useNavigate()

  return (
    <Button
      sx={{ mt: 2, ml: 2 }}
      variant="outlined"
      color="secondary"
      onClick={() => {
        navigate("/links")
      }}
    >
      Back to links
    </Button>
  )
}
