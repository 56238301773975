import { useNavigate } from "react-router-dom"
import { useContext } from "react"

import { useTheme } from "@mui/material/styles"
import { ModalContext } from "contexts/ModalContext"
import { MODAL_TYPES } from "components/Modal/ModalTypes"

// mui
import Card from "@mui/material/Card"
import CardHeader from "@mui/material/CardHeader"
import CardActionArea from "@mui/material/CardActionArea"

export const NoOrganizationCard = () => {
  let navigate = useNavigate()
  const theme = useTheme()
  const { setModalState } = useContext(ModalContext)

  const openCreateOrganizationModal = () => {
    setModalState({
      isOpen: true,
      modalType: MODAL_TYPES.CREATE_ORGANIZATION,
    })
  }

  return (
    <Card
      sx={{
        mx: 1,
        my: 2,
        borderRadius: 2,
        borderWidth: "1px",
        borderStyle: "solid",
        borderColor: theme.palette.divider,
        boxShadow: 0,
      }}
    >
      <CardActionArea
        onClick={() => {
          openCreateOrganizationModal()
          navigate("/organizations")
        }}
      >
        <CardHeader title="Create New" subheader="Create a new organization" />
      </CardActionArea>
    </Card>
  )
}
