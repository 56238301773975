import { useEffect, useState } from "react"
import { useQuery } from "@apollo/client"
import { getFileUploadsQuery } from "queries/queries"
import { useTheme } from "@mui/material/styles"

import Typography from "@mui/material/Typography"
import Grid from "@mui/material/Grid"
import Button from "@mui/material/Button"
import Table from "@mui/material/Table"
import TableBody from "@mui/material/TableBody"
import TableCell from "@mui/material/TableCell"
import TableContainer from "@mui/material/TableContainer"
import TableHead from "@mui/material/TableHead"
import TableRow from "@mui/material/TableRow"
import Paper from "@mui/material/Paper"
import CircularProgress from "@mui/material/CircularProgress"
import { SxProps } from "@mui/material"

import { formatDateTime } from "Utils/Utils"

interface ImportTableInterface {
  textStyle: SxProps
  setShowTable: (arg: boolean) => void
}

export const ImportTable = (props: ImportTableInterface) => {
  const { textStyle, setShowTable } = props
  const { data, startPolling, stopPolling } = useQuery(getFileUploadsQuery)
  const theme = useTheme()
  const [count, setCount] = useState(60)
  const IMPORTING_STATE = "Importing"

  useEffect(() => {
    if (
      data &&
      data.fileUploads &&
      data.fileUploads.length &&
      data.fileUploads[data.fileUploads.length - 1].userFriendlyState === IMPORTING_STATE
    ) {
      startPolling(5000)
    }
    return () => {
      stopPolling()
    }
  }, [data, startPolling, stopPolling])

  useEffect(() => {
    let timer: NodeJS.Timeout
    if (count > 0) {
      timer = setTimeout(() => setCount(count - 1), 1000)
    } else {
      // stop Polling after 60 seconds
      stopPolling()
    }
    return () => clearTimeout(timer) // Cleanup on unmount or count reaching 0
  }, [count, stopPolling])

  const determineRowColor = (state: string) => {
    if (state === IMPORTING_STATE) {
      return theme.palette.text.disabled
    } else if (state === "Imported") {
      return theme.palette.success.main
    } else {
      return theme.palette.warning.main
    }
  }

  const handleClick = () => {
    setShowTable(false)
  }

  const renderTable = () => (
    <>
      <Grid item xs={12} md={6} sx={{ my: 2 }}>
        <TableContainer component={Paper} sx={{ ...textStyle }}>
          <Typography sx={{ textAlign: "center" }} variant="h6">
            Your Imports:
          </Typography>
          <Table sx={{ minWidth: 300 }} size="small" aria-label="Uploads">
            <TableHead>
              <TableRow>
                <TableCell align="center">
                  <Typography>Import Status</Typography>
                </TableCell>
                <TableCell align="center">
                  <Typography>Message</Typography>
                </TableCell>
                <TableCell align="center">
                  <Typography>Uploaded At</Typography>
                </TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {data?.fileUploads.map((file_upload: any, idx: number) => (
                <TableRow key={idx} sx={{ "&:last-child td, &:last-child th": { border: 0 } }}>
                  <TableCell align="center" component="th" scope="row">
                    <Typography color={determineRowColor(file_upload.userFriendlyState)}>
                      {file_upload.userFriendlyState}
                    </Typography>
                  </TableCell>
                  <TableCell align="center" component="th" scope="row" sx={{}}>
                    <Typography color={determineRowColor(file_upload.userFriendlyState)}>
                      {file_upload.errorMessage}
                      {file_upload.userFriendlyState === IMPORTING_STATE && (
                        <CircularProgress color="primary" size="20px" sx={{ mt: 1, mr: 1 }} />
                      )}
                    </Typography>
                  </TableCell>
                  <TableCell align="center" component="th" scope="row">
                    <Typography color={determineRowColor(file_upload.userFriendlyState)}>
                      {formatDateTime(file_upload.createdAt)}
                    </Typography>
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
          <Button sx={{ ml: 2 }} onClick={handleClick}>
            Hide Past Imports
          </Button>
        </TableContainer>
      </Grid>

      <Grid item xs={12} md={6} sx={{ display: { xs: "none", md: "block" } }}></Grid>
    </>
  )

  return <>{data?.fileUploads?.length ? renderTable() : ""}</>
}
