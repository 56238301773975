import React from "react"

// Components
import { VerifyEmail } from "components/VerifyEmail"
import { BackToLinksSubHeader } from "components/BackToLinksSubHeader"

export function VerifyEmailPage() {
  return (
    <>
      <BackToLinksSubHeader />
      <VerifyEmail />
    </>
  )
}
